const handlers = [];

class PageLoadHandler {
  constructor() {
    this.handlers = [];
  }

  register(handler) {
    this.handlers.push(handler);
  }

  handlePageLoad() {
    this.handlers.forEach(h => h());
  }
}

const handler = new PageLoadHandler();
document.addEventListener('turbolinks:load', handler.handlePageLoad.bind(handler));
export default function(loadHandler) {
  handler.register(loadHandler)
};
