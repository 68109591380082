import registerController from '../utilities/register-controller';

class StaticController {
  about() {
    console.log('This is the about page');
  }
}

registerController('static', StaticController);

export default StaticController;
