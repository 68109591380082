import registerPageLoadHandler from './register-page-load-handler';

const handlers = {};

registerPageLoadHandler(() => {
  const { controller, action } = document.body.dataset;
  if (!handlers[controller]) {
    return
  }
  const instance = new handlers[controller]();
  if (instance[action]) {
    instance[action]();
  }
});

const registerHandler = (controllerName, handler) => {
  handlers[controllerName] = handler;
};

export default registerHandler;
